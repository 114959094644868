<template>
 
 <div class="navbar-custom">
            <div class="topbar container-fluid">
                <div class="d-flex align-items-center gap-1">
                    <div class="logo-topbar">
                        <a href="" class="logo-light">
                            <span class="logo-lg">
                                二手旧衣物回收平台
                            </span>
                            <span class="logo-sm">
                                二手旧衣物回收平台
                            </span>
                        </a>
                        <a href="" class="logo-dark">
                            <span class="logo-lg">
                                二手旧衣物回收平台
                            </span>
                            <span class="logo-sm">
                                二手旧衣物回收平台
                            </span>
                        </a>
                    </div>
              
               
                </div>
                <ul class="topbar-menu d-flex align-items-center gap-3">
                 
           
                    
                    
              
                    <li class="dropdown">
                        <a class="nav-link dropdown-toggle arrow-none nav-user" data-bs-toggle="dropdown" href="#"
                            role="button" aria-haspopup="false" aria-expanded="false">
                            <span class="account-user-avatar">
                                <img :src="plogo" alt="user-image" width="32"
                                    class="rounded-circle">
                            </span>
                            <span class="d-lg-block d-none">
                                <h5 class="my-0 fw-normal">【<b style="color: #d03f3f;">{{role}}</b>】{{ userLname }} <i
                                        class="ri-arrow-down-s-line d-none d-sm-inline-block align-middle"></i></h5>
                            </span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end dropdown-menu-animated profile-dropdown">
                            <div class=" dropdown-header noti-title">
                                <h6 class="text-overflow m-0">欢迎您 !</h6>
                            </div>
                      
                            <!-- <a href="/" class="dropdown-item" target="_blank">
                                <i class="ri-home-2-fill fs-18 align-middle me-1"></i>
                                <span>网站首页</span>
                            </a> -->
                            <a href="/Password" class="dropdown-item">
                                <i class="ri-lock-password-line fs-18 align-middle me-1"></i>
                                <span>修改密码</span>
                            </a>
                            <a href="javascript:void(0)" class="dropdown-item"  @click="exit">
                                <i class="ri-logout-box-line fs-18 align-middle me-1"></i>
                                <span>退出登录</span>
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
      
</template>
<script>
export default {
  data() {
    return {
      plogo: require("../assets/img/avatar-1.jpg"),
      userLname: "",
      role: "",
      clicknav: false
    };
  },
  mounted() {
    this.userLname = sessionStorage.getItem("userLname");
    this.role = sessionStorage.getItem("role");
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    showexists() {
      console.log(333);
      this.showexist = !this.showexist;
    },

    exit: function () {
      var _this = this;
      this.$confirm("确认退出吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          sessionStorage.removeItem("userLname");
          sessionStorage.removeItem("role");
          _this.$router.push("/");
        })
        .catch(() => { });
    },
  },
};
</script>

<style >
    .logo-sm{
            font-size: 20px;
            font-weight: bold;
            /* 超宽自动隐藏 */
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

        }
        .logo-lg{
            font-size: 20px;
            font-weight: bold;
        }
</style>

