<template>
  <div class="leftside-menu menuitem-active">
    <a href="/main" class="logo logo-light">
      <span class="logo-lg">
        二手旧衣物回收平台
      </span>
      <span class="logo-sm">
        二手旧衣物回收平台
      </span>
    </a>
    <a href="/main" class="logo logo-dark">
      <span class="logo-lg">
        二手旧衣物回收平台
      </span>
      <span class="logo-sm">
        二手旧衣物回收平台
      </span>
    </a>
    <div class="h-100 show" id="leftside-menu-container" data-simplebar="init">
      <div class="simplebar-wrapper" style="margin: 0px;">
        <div class="simplebar-height-auto-observer-wrapper">
          <div class="simplebar-height-auto-observer"></div>
        </div>
        <div class="simplebar-mask">
          <div class="simplebar-offset" style="right: 0px; bottom: 0px;">
            <div class="simplebar-content-wrapper" tabindex="0" role="region" aria-label="scrollable content"
              style="height: 100%; overflow: hidden;">
              <div class="simplebar-content" style="padding: 0px;">
                <ul class="side-nav">
                  <li class="side-nav-title">功能菜单</li>

                  <el-menu style="width:240px; " router unique-opened="true" active-text-color="#36A9B0"
                    background-color="#1A2942" text-color="#70809A" class="el-menu-vertical-demo">

                    <el-submenu index="8">
                      <template #title>
                        <i class="el-icon-notebook-2"></i>
                        <span>用户管理</span>
                      </template>
                      <el-menu-item-group>
                        <el-menu-item index="/membersManage"><i class="el-icon-position"></i>管理用户</el-menu-item>

                      </el-menu-item-group>
                    </el-submenu>

                    <el-submenu index="3">
                      <template #title>
                        <i class="el-icon-notebook-2"></i>
                        <span>轮播图管理</span>
                      </template>
                      <el-menu-item-group>
                        <el-menu-item index="/bannerAdd"><i class="el-icon-position"></i>添加轮播图</el-menu-item>
                        <el-menu-item index="/bannerManage"><i class="el-icon-position"></i>管理轮播图</el-menu-item>

                      </el-menu-item-group>
                    </el-submenu>

                    <el-submenu index="4">
                      <template #title>
                        <i class="el-icon-notebook-2"></i>
                        <span>环保问题管理</span>
                      </template>
                      <el-menu-item-group>
                        <el-menu-item index="/environmentAdd"><i class="el-icon-position"></i>添加环保问题</el-menu-item>
                        <el-menu-item index="/environmentManage"><i class="el-icon-position"></i>管理环保问题</el-menu-item>

                      </el-menu-item-group>
                    </el-submenu>

                    <el-submenu index="10">
                      <template #title>
                        <i class="el-icon-notebook-2"></i>
                        <span>知识宣传管理</span>
                      </template>
                      <el-menu-item-group>
                        <el-menu-item index="/knowledgeAdd"><i class="el-icon-position"></i>添加知识宣传</el-menu-item>
                        <el-menu-item index="/knowledgeManage"><i class="el-icon-position"></i>管理知识宣传</el-menu-item>

                      </el-menu-item-group>
                    </el-submenu>

                    <el-submenu index="1">
                      <template #title>
                        <i class="el-icon-notebook-2"></i>
                        <span>活动管理</span>
                      </template>
                      <el-menu-item-group>
                        <el-menu-item index="/activityAdd"><i class="el-icon-position"></i>添加活动</el-menu-item>
                        <el-menu-item index="/activityManage"><i class="el-icon-position"></i>管理活动</el-menu-item>

                      </el-menu-item-group>
                    </el-submenu>


                    <el-submenu index="6">
                      <template #title>
                        <i class="el-icon-notebook-2"></i>
                        <span>回收管理</span>
                      </template>
                      <el-menu-item-group>
                        <el-menu-item index="/recyclesManage"><i class="el-icon-position"></i>待回收订单</el-menu-item>
                        <el-menu-item index="/recyclesManage2"><i class="el-icon-position"></i>已回收订单</el-menu-item>

                      </el-menu-item-group>
                    </el-submenu>

                    <el-submenu index="7">
                      <template #title>
                        <i class="el-icon-notebook-2"></i>
                        <span>积分商品管理</span>
                      </template>
                      <el-menu-item-group>
                        <el-menu-item index="/productAdd"><i class="el-icon-position"></i>添加积分商品</el-menu-item>
                        <el-menu-item index="/productManage"><i class="el-icon-position"></i>管理积分商品</el-menu-item>

                      </el-menu-item-group>
                    </el-submenu>

                    <el-submenu index="5">
                      <template #title>
                        <i class="el-icon-notebook-2"></i>
                        <span>商品订单管理</span>
                      </template>
                      <el-menu-item-group>

                        <el-menu-item index="/ordersManage"><i class="el-icon-position"></i>管理商品订单</el-menu-item>

                      </el-menu-item-group>
                    </el-submenu>



                    <el-submenu index="9">
                      <template #title>
                        <i class="el-icon-notebook-2"></i>
                        <span>反馈管理</span>
                      </template>
                      <el-menu-item-group>
                        <el-menu-item index="/feedbackManage"><i class="el-icon-position"></i>管理反馈</el-menu-item>

                      </el-menu-item-group>
                    </el-submenu>



                    <el-submenu index="55">
                      <template #title>
                        <i class="el-icon-notebook-2"></i>
                        <span>系统管理</span>
                      </template>
                      <el-menu-item-group>

                        <el-menu-item index="password"><i class="el-icon-position"></i>修改密码</el-menu-item>
                      </el-menu-item-group>
                    </el-submenu>
                  </el-menu>


                </ul>
                <div class="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="simplebar-placeholder" style="width: auto; height: 680px;"></div>
      </div>
      <div class="simplebar-track simplebar-horizontal" style="visibility: hidden;">
        <div class="simplebar-scrollbar" style="width: 0px; display: none;"></div>
      </div>
      <div class="simplebar-track simplebar-vertical" style="visibility: hidden;">
        <div class="simplebar-scrollbar" style="height: 0px; display: none;"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "LeftMenu",
  data() {
    return {
      userLname: "",
      role: "",
    };
  },
  mounted() {
    this.userLname = sessionStorage.getItem("userLname");
    this.role = sessionStorage.getItem("role");
  },
  created() {

    //如果没有登录，跳转到登录页面
    if (this.userLname == null) {
      this.$router.push("/");
    }

  },
};
</script>

<style scoped></style>




