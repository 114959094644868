<template>
  <html data-bs-theme="light" data-layout-mode="fluid" data-menu-color="dark" data-topbar-color="light"
        data-layout-position="fixed" data-sidenav-size="default" class="menuitem-active">
  <ElConfigProvider :locale="locale">

    <div class="wrapper">
      <Header />

      <LeftMenu />

      <div class="content-page">
        <div class="content">
          <div class="container-fluid">
            <router-view />
          </div>
        </div>  
      </div>
    </div>
  </ElConfigProvider>

  </html>
</template>

<script>
import Header from "../components/Header";
import LeftMenu from "../components/LeftMenu";
import { ElConfigProvider } from "element-plus";
import zhCn from "element-plus/lib/locale/lang/zh-cn";

import "../assets/js/vendor.min.js";

export default {
  name: "MainLayout",
  components: {
    Header,
    LeftMenu,
    [ElConfigProvider.name]: ElConfigProvider,
  },
  data() {
    return {
      locale: zhCn,
    };
  },
  mounted() {


  },

  methods: {

  },
};
</script>

<style>
@import url(../assets/css/app.min.css);
@import url(../assets/css/icons.min.css);

body{
  background-image: url(../assets/img/auth-bg.jpg);
  background-size: cover;
}
.form-control{
  border: 1px solid #3bc0c3;
}
.form-control:focus{
  border: 2px solid #c01750;
  box-shadow: none;
}
</style>


